import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import styled from "styled-components"


import { Section, Container } from "../components/global"


const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <Navigation home={false} />
    <Section id="missie">
    <StyledContainer>
      <SectionTitle>Privacy Statement</SectionTitle>
      <Paragraph>
      ThinkYou vindt uw privacy belangrijk. Deze Privacy Statement heeft betrekking op de informatie verzameld door de ThinkYou app.
      Door uw persoonsgegevens op te geven op in de app, gaat u ermee akkoord dat wij die gegevens in overeenstemming met deze Privacy Statement gebruiken.
      </Paragraph>
      <Paragraph>
      Dit beleid heeft betrekking op de ThinkYou app. Wij kunnen niet verantwoordelijk gehouden worden voor het privacy beleid of privacy handelingen van andere websites of apps.
      </Paragraph>
      <Paragraph>
      ThinkYou vraagt alleen de nodige gegevens voor het functioneren van de app. Deze gegevens zijn niet beschikbaar voor andere gebruikers of voor websites die geopend worden vanuit de app. De gegevens worden opgeslagen op de beveiligde servers van ThinkYou. Gegevens kunnen worden geaggregeerd en gebruikt voor analyse doeleinden om ThinkYou te verbeteren.
      </Paragraph>
      <Paragraph>
      ThinkYou vraagt toegang tot je telefooncontacten en optioneel tot je telefoonagenda, om het makkelijk te maken om personen toe te voegen. ThinkYou slaat niet automatisch gegevens van jouw contacten of agenda op. Alleen de gegevens die je zelf toevoegt worden opgeslagen op onze servers. 
      </Paragraph>
      <Paragraph>
      Ons beleid is om persoonsgegevens niet aan derden te verstrekken, tenzij wij daartoe verplicht zijn op grond van de wet of een rechterlijke uitspraak of u ons daar zelf uitdrukkelijk om heeft verzocht. Wij realiseren ons dat uw persoonsgegevens waardevol zijn en nemen alle redelijke maatregelen om ze te beschermen.
      </Paragraph>
      <Paragraph>
      ThinkYou behoudt zich het recht voor om deze Privacy Statement te wijzigen.
      </Paragraph>
      <Paragraph>
      Mocht u vragen hebben, neem dan contact op met <a href="mailto:info@thinkyou.care">info@thinkyou.care</a>
      </Paragraph>
    </StyledContainer>
    </Section>
    {/* <Footer /> */}
  </Layout>
)

export default PrivacyPage


const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 40px;
  text-align: center;
`


const Paragraph = styled.p`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`
